import request from './request.js';
import public_request from './public_request';
//获取数据简报
export function brief_report({ departmentId, staffId }) {
    return request({
        url: '/index/brief-report',
        method: 'get',
        params: public_request({
            departmentId,
            staffId,
        }),
    });
}
//获取数据简报
export function clue_quality({ departmentId, staffId, source, startTime, endTime }) {
    return request({
        url: '/index/clue-quality',
        method: 'get',
        params: public_request({
            departmentId,
            staffId,
            source,
            startTime,
            endTime,
        }),
    });
}

//获取销售漏斗数据
export function sales_funnel_data({ departmentId, staffId, startTime, endTime }) {
    return request({
        url: '/index/sales-funnel-data',
        method: 'get',
        params: public_request({
            departmentId,
            staffId,
            startTime,
            endTime,
        }),
    });
}
