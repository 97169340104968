<template>
    <div class="home">
        <div class="content">
            <!-- 我的选项 -->
            <div class="block">
                <el-cascader
                    ref="cascaderHandle"
                    size="small"
                    v-model="ccc"
                    :options="options"
                    :props="{expandTrigger: 'hover', checkStrictly: true, label: 'name', value: 'id'}"
                    placeholder="选择部门"
                    @change="casc"
                />
                <el-select
                    v-if="ccc[0] !== '我的'"
                    v-model="cc"
                    clearable
                    placeholder="选择个人"
                    @change="cascren"
                >
                    <el-option
                        v-for="item in options1"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>
            </div>
            <!-- 今日的数据 -->
            <div class="top">
                <div class="row">
                    <div class="item">
                        <div class="k">
                            <h2>今日分发线索数</h2>
                            <h1>{{ content.clueDistributeTodayCount }}</h1>
                            <p>
                                总分发线索数：
                                <span>{{ content.clueDistributeTotal }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="k">
                            <h2>今日线索转客户</h2>
                            <h1>{{ content.clueTransferTodayRate }}</h1>
                            <p>
                                全部线索转化率：
                                <span>{{ content.clueTransferTotalRate }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="k">
                            <h2>今日跟进客户数</h2>
                            <h1>{{ content.clientFollowTodayCount }}</h1>
                            <p>
                                全部客户数：
                                <span>{{ content.clientCount }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="k">
                            <h2>本周签约客户数</h2>
                            <h1>{{ content.clientSignWeekCount }}</h1>
                            <p>
                                全部签约客户数：
                                <span>{{ content.clientSignTotalCount }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="k">
                            <h2>本周放款客户数</h2>
                            <h1>{{ content.clientLentWeekCount }}</h1>
                            <p>
                                全部放款客户数：
                                <span>{{ content.clientLentTotalCount }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 图表展示 -->
            <div class="bottom">
                <!-- 日期选择 -->
                <div class="t">
                    <span
                        v-for="(item, index) in xuanxiang"
                        :key="index"
                        :class="{ active: index == isShow }"
                        @click="fn(index)"
                    >{{ item }}</span>
                    <el-date-picker
                        v-model="value1"
                        size="small"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="timestamp"
                        :default-time="['00:00:00', '23:59:59']"
                        @change="time"
                    />
                </div>
                <!-- 图表 -->
                <el-row :gutter="20">
                    <!-- 左侧的两个图表 -->
                    <el-col :span="16">
                        <div class="grid-content bg-purple left">
                            <div class="k">
                                <div class="kt">
                                    <div class="l">
                                        <span>线索质量情况</span>
                                    </div>
                                    <div class="r">
                                        <el-select v-model="value3" placeholder="请选择">
                                            <el-option
                                                v-for="item in options3"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                                <div class="tubiao">
                                    <div class="kk">
                                        <div
                                            id="myChart"
                                            :style="{width: '100%',height: '100%',minHeight: '300px'}"
                                        />
                                    </div>
                                    <div class="kk kuang">
                                        <div
                                            id="myChart1"
                                            :style="{ width: '100%', height: '100%' }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <!-- 右侧的一个图表 -->
                    <el-col :span="8">
                        <div class="grid-content bg-purple right">
                            <div class="k">
                                <div class="kt">
                                    <span>客户跟进情况</span>
                                </div>
                                <div class="tubiao">
                                    <div class="kk">
                                        <div
                                            id="myChart2"
                                            :style="{width: '100%', height: '100%', margin: 'auto', minHeight: '300px'}"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import { time } from '../assets/js/selectTime.js';
import { brief_report, clue_quality, sales_funnel_data } from '../api/home.js';
import { departments, staff, login_user } from '../api/public_content.js';
// import { filter } from 'vue/types/umd';
let softPhoneUA = '';
// let adminName = ''
export default {
    // props: ['allValue'],
    name: 'FuncFormsBase',
    data() {
        return {
            clueQualityPiaChart: null,
            clueQualityBarChart: null,
            selasFunnalChart: null,
            content: {},
            cc: '',
            options1: [],
            ccc: ['我的'],
            options: [],
            value: '',
            value1: '',
            xuanxiang: ['全部', '最近一周', '最近一月', '今日', '昨日'],
            isShow: 0,
            value3: '线索渠道',
            options3: [],
            ce: '',
            shuju: [],
            stars: [],
            clientinformation: [],
            dataRes: [],
            purpose: ['潜在', '考虑', '有兴趣', '急需'],
            allStars: [],
            adminName: '',
        };
    },
    created() {
        // let purpose = this.common.allValueNeed("loan-purpose-level");
        // for (let i = 0; i < purpose.length; i++) {
        //   this.purpose.push(purpose[i].cnVal);
        // }
        this.getLoginUser();
        if (this.debugOpen) console.log('home.vue -> created()');
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: 'getLoginUser',

        // allValue(value) {
        // console.log(value);
        // if (value) {
        // let purpose = this.common.sigelValueNeed("loan-purpose-level",value);
        // console.log(purpose);
        // for (let i = 0; i < purpose.length; i++) {
        //   this.purpose.push(purpose[i].cnVal);
        // }
        // }
        // },
    },
    mounted() {
        if (this.debugOpen) console.log('home.vue -> mounted()');
        //  let purpose = this.allValue
        // console.log(purpose);
        // location.reload()
        //默认渲染图表
        this.chart(null, null, '', '', '');
        //部门菜单获取
        departments().then((res) => {
            this.options = res.data.list;
            this.options.unshift({
                name: '我的',
                id: '我的',
            });
        });
        // window.addEventListener("setItem", () => {
        //   this.$nextTick(() => {
        //     let purpose = this.common.allValueNeed("loan-purpose-level");
        //     for (let i = 0; i < purpose.length; i++) {
        //       this.purpose.push(purpose[i].cnVal);
        //     }
        //     console.log(this.purpose);
        //   });
        // });
        //查字典获取意向度
        // this.$nextTick(() => {
        //   console.log(88888);
        //   let purpose = this.common.allValueNeed("loan-purpose-level");
        //   for (let i = 0; i < purpose.length; i++) {
        //     this.purpose.push(purpose[i].cnVal);
        //   }

        // });

        // this.$store.commit('callOn', 2)
    },
    beforeDestroy() {
        if (this.debugOpen) console.log('home.vue -> beforeDestroy()', this.clueQualityPiaChart);
        //  销毁 echarts 实例
        if (this.clueQualityPiaChart) {
            this.clueQualityPiaChart.dispose();
            this.clueQualityPiaChart = null;
        }
        if (this.clueQualityBarChart) {
            this.clueQualityBarChart.dispose();
            this.clueQualityBarChart = null;
        }
        if (this.selasFunnalChart) {
            this.selasFunnalChart.dispose();
            this.selasFunnalChart = null;
        }
    },
    methods: {
        //获取登录用户信息
        getLoginUser() {
            login_user({}).then((res) => {
                this.adminName = res.data.name;
                window.sessionStorage.setItem('sipToken', res.data.ctiToke);
                // console.log(res.data.sipIceServer,'res.data.sipIceServer')
                // let callState = this.$store.state.callStatus

                // let uuid = ''
                // uuid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                // let callUUid = ''
                // callUUid = (uuid + uuid + "-" + uuid + "-" + uuid + "-" + uuid + "-" + uuid + uuid + uuid)

                // sip登入
                let config = {
                    username: res.data.username, //用户名
                    wechatServiceEnabled: res.data.wechatServiceEnabled, // 微信管理服务启用，0=禁用，1-启用
                    phoneNumberHideEnabled: res.data.phoneNumberHideEnabled, // 电话号码隐藏，0=否，1=是
                    ctiServiceEnabled: res.data.ctiServiceEnabled,
                    callServiceEnabled: res.data.callServiceEnabled,
                    uri: res.data.sipPublicIdentity, //sip地址(必须)
                    wsServers: res.data.sipWSSHost, //wss地址(必须)
                    authorizationUser: res.data.sipPrivateIdentity, // 授权号(必须)
                    displayName: res.data.name, // 显示名称(必须)
                    password: res.data.sipPassword, // 登陆密码(必须)
                    allowLegacyNotifications: true,
                    autostart: true, // 自动连接
                    // stunServers:res.data.sipIceServer, //stun服务器的，需要开的时候就开吧
                    traceSip: true, //打开连接日志
                    // register: true, // 自动就绪
                    iceCheckingTimeout: 5000,
                    // turnServers:res.data.sipIceServer,
                    rel100: SIP.C.supported.SUPPORTED, // 该选项开启接收早期媒体(采坑记录)(必须)
                    noAnswerTimeout: 120, //无应答超时(这个是接收运营商回铃音的必要参数,默认60)(必须)
                    // instanceId:callUUid ,//指示 UUID URI 用作实例 ID，以在使用 GRUU 时标识 UA 实例
                    wsServerMaxReconnection: 3, //服务器重连最大值
                };

                if (this.debugOpen)
                    console.log(
                        'home.vue -> getLoginUser() -> login_user()',
                        config
                    );

                this.$store.commit(
                    'changeWechatState',
                    res.data.wechatServiceEnabled
                );
                this.$EventBus.$emit('LoginSIP', config);
                this.$EventBus.$emit('loginName', this.adminName);
                this.$store.commit('login_callInfor', JSON.stringify(config));
            });
        },

        //  绑定ua事件
        UAEvent(ua) {
            // 开始尝试连接
            ua.on('connecting', function (args) {
                console.log('%c connecting', 'color: #f00');
            });
            // 连接完毕
            ua.on('connected', function () {
                console.log('%c connected', 'color: #f00');
            });
            // 主动取消注册或注册后定期重新注册失败
            ua.on('unregistered', function (response, cause) {
                console.log('%c unregistered', 'color: #f00');
            });
            // 注册成功
            ua.on('registered', function () {
                console.log('%c registered', 'color: #f00');
            });
            // websocket 连接失败
            ua.on('disconnected', function () {
                console.log('%c disconnected', 'color: #f00');
            });
        },

        //图表渲染的封装
        chart(departmentId, staffId, source, startTime, endTime) {
            if (staffId != '') {
                departmentId = null;
            }
            if (departmentId == 0 || departmentId == '我的') {
                departmentId = null;
            }
            //获取数据简报
            brief_report({
                departmentId: departmentId,
                staffId: staffId,
            }).then((res) => {
                if (res.code == 0) {
                    this.content = res.data;
                } else {
                    this.$message.error(res.error);
                }
            });
            //获取线索质量情况
            clue_quality({
                departmentId: departmentId,
                staffId: staffId,
                source: source,
                startTime: startTime,
                endTime: endTime,
            }).then((res) => {
                if (res.code == 0) {
                    this.dataRes = res;
                    this.stars = res.data.list;
                    this.stars.forEach((item) => {
                        item.value = item.count;
                    });
                    let arr = res.data.list;
                    let potential = 0,
                        think = 0,
                        interest = 0,
                        quickNeed = 0;
                    // let allData = []
                    // arr.forEach((item, index) => {
                    //   that.shuju.push(arr[index][Object.keys(arr[index])[0]]);
                    // });iy
                    arr.forEach((item) => {
                        item.purposeLevelList.forEach((i) => {
                            if (i['潜在'] || i['潜在'] != undefined)
                                potential = potential + i['潜在'];
                            if (i['考虑'] || i['考虑'] != undefined)
                                think = think + i['考虑'];
                            if (i['有兴趣'] || i['有兴趣'] != undefined)
                                interest = interest + i['有兴趣'];
                            if (i['急需'] || i['急需'] != undefined)
                                quickNeed = quickNeed + i['急需'];
                            // ,
                            //
                            //
                        });
                    });
                    this.allStars = [potential, think, interest, quickNeed];
                    this.drawLine();
                    this.drawLine1();
                } else {
                    this.$message.error(res.error);
                }
            });
            //漏斗显示
            sales_funnel_data({
                departmentId: departmentId,
                staffId: staffId,
                startTime: startTime,
                endTime: endTime,
            }).then((res) => {
                if (res.code == 0) {
                    this.clientinformation = res.data.list;
                    this.drawLine2();
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //选择部门
        casc(val) {
            console.log(val, 'val');
            this.$refs.cascaderHandle.dropDownVisible = false;
            if (val[0] != '我的') {
                staff({
                    departmentId: val[val.length - 1],
                }).then((res) => {
                    if (res.code == 0) {
                        this.options1 = res.data.list;
                    } else {
                        this.$message.error(res.error);
                    }
                });
                this.chart(val[val.length - 1], '', '', '', '');
            } else {
                this.chart(null, null, '', '', '');
            }
            this.cc = '';
        },
        //选择部门员工
        cascren(val) {
            this.chart(this.ccc[this.ccc.length - 1], val, '', '', '');
        },
        // 标签选择当前的日期
        fn(e) {
            this.isShow = e;
            this.value1 = time(e, this.xuanxiang);
            this.shuju = [];
            this.ce = '';
            this.chart(
                this.ccc[this.ccc.length - 1],
                this.cc,
                '',
                this.value1[0] / 1000,
                this.value1[1] / 1000
            );
        },
        //时间选择器发生改变时取消标签的选择
        time() {
            if (this.value1) {
                this.isShow = NaN;
                this.chart(
                    this.ccc[this.ccc.length - 1],
                    this.cc,
                    '',
                    this.value1[0] / 1000,
                    this.value1[1] / 1000
                );
            } else {
                this.fn(0);
            }
        },
        //第一个图表
        drawLine() {
            let that = this;
            //给图表添加点击事件，改变第二个图表的内容
            if (this.clueQualityPiaChart) {
                this.clueQualityPiaChart.dispose();
                this.clueQualityPiaChart = null;
            }
            this.clueQualityPiaChart = this.$echarts.getInstanceByDom(
                document.getElementById('myChart')
            );
            this.clueQualityPiaChart = this.$echarts.init(
                document.getElementById('myChart')
            );
            this.clueQualityPiaChart.on('pieselectchanged', function (params) {
                if (params.selected[params.name]) {
                    let index = '';
                    for (let key in params.selected) {
                        if (
                            params.selected[params.name] == params.selected[key]
                        ) {
                            index = key[0];
                        }
                    }
                    that.ce = params.name;
                    let arr = that.dataRes.data.list[index].purposeLevelList;
                    that.shuju = [];
                    arr.forEach((item, index) => {
                        that.shuju.push(arr[index][Object.keys(arr[index])[0]]);
                    });
                    that.drawLine1();
                } else {
                    that.shuju = [];
                    that.drawLine1();
                }
            });
            //图标的配置
            this.clueQualityPiaChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                color: [
                    '#E7BCF3',
                    '#FF9F7F',
                    '#FFE074',
                    '#9FE6B8',
                    '#5FA4EB',
                    '#8378EA',
                ],
                legend: {
                    bottom: 0,
                    left: 'center',
                    data: ['0星', '1星', '2星', '3星', '4星', '5星'],
                },
                series: [
                    {
                        radius: ['20%', '70%'],
                        selectedMode: 'single',
                        name: '客户星级',
                        type: 'pie',
                        center: ['50%', '50%'],
                        data: that.stars,
                    },
                ],
            });
        },

        //第二个图表
        drawLine1() {
            let that = this;
            if (this.clueQualityBarChart) {
                this.clueQualityBarChart.dispose();
                this.clueQualityBarChart = null;
            }
            this.clueQualityBarChart = this.$echarts.getInstanceByDom(
                document.getElementById('myChart1')
            );
            this.clueQualityBarChart = this.$echarts.init(
                document.getElementById('myChart1')
            );
            // this.stars.forEach((item,index) => {

            // })
            //图表的配置
            this.clueQualityBarChart.setOption({
                //通过第一个图表的选择来控制这个图表的title
                title: {
                    text:
                        '线索意向度' +
                        (that.shuju.length > 0 ? that.ce : '(全部)'),
                },
                color: ['#5FA4EB'],
                xAxis: {
                    type: 'value',
                },
                yAxis: {
                    type: 'category',
                    data: that.purpose,
                },
                grid: {
                    left: '3%',
                    right: '6%',
                    containLabel: true,
                },
                //通过第一个图表的选择来控制这个图表的data
                series: [
                    {
                        data:
                            that.shuju.length > 0 ? that.shuju : that.allStars,
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right', //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 16,
                                    },
                                },
                            },
                        },
                    },
                ],
            });
        },
        //右侧的图表
        drawLine2() {
            if (this.selasFunnalChart) {
                this.selasFunnalChart.dispose();
                this.selasFunnalChart = null;
            }
            this.selasFunnalChart = this.$echarts.getInstanceByDom(
                document.getElementById('myChart2')
            );
            this.selasFunnalChart = this.$echarts.init(document.getElementById('myChart2'));
            let arrKay = [],
                arrValue = [];
            this.clientinformation.forEach((item, index) => {
                arrKay.push(Object.keys(this.clientinformation[index])[0]);
                arrValue.push({
                    value: this.clientinformation[index][
                        Object.keys(this.clientinformation[index])[0]
                    ],
                    name: Object.keys(this.clientinformation[index])[0],
                });
            });
            //图表的配置
            this.selasFunnalChart.setOption({
                title: {
                    show: false,
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}',
                },

                color: ['#EF7878', '#FF9F7F', '#37A2DA', '#67E0E3', '#FFDB5C'],
                legendHoverLink: false,
                series: [
                    {
                        name: '漏斗图',
                        type: 'funnel',
                        left: '10%',
                        width: '80%',
                        min: 1,
                        max: 100,
                        minSize: '10%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 2,
                        label: {
                            position: 'inside',
                            formatter: '',
                            color: '#fff',
                        },
                        labelLine: {
                            show: false,
                        },
                        tooltip: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                                name: '线索',
                            },
                            {
                                value: 80,
                                name: '沟通',
                            },
                            {
                                value: 60,
                                name: '上门',
                            },
                            {
                                value: 40,
                                name: '签约',
                            },
                            {
                                value: 20,
                                name: '放款',
                            },
                        ],
                    },
                    {
                        name: '漏斗图',
                        type: 'funnel',
                        left: '10%',
                        width: '80%',
                        sort: 'none',
                        gap: 2,
                        label: {
                            position: 'inside',
                            formatter: '{b} : {c}',
                            color: '#fff',
                        },
                        labelLine: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                borderColor: 'transparent', // 图形边框颜色
                                opacity: '0',
                                color: 'transparent',
                            },
                        },
                        data: arrValue,
                    },
                ],
            });
        },
    },
};
</script>
<style lang="less" scoped>
.home {
    padding: 20px;
    height: calc(100vh - 60px);

    .content > p {
        ::v-deep .el-input__inner {
            border: 0;
            width: 100px;
            padding: 0;
        }
    }

    .content {
        padding: 20px;
        background: white;
        width: 100%;

        .top {
            margin-top: 10px;
            padding: 20px 40px 30px 40px;
            border: 1px solid rgba(181, 181, 181, 1);
            border-radius: 4px;

            .row {
                margin-left: -26px;
                margin-right: -26px;
                overflow: hidden;

                .item {
                    width: 20%;
                    float: left;
                    padding-left: 26px;
                    padding-right: 26px;

                    .k {
                        background: rgba(242, 250, 255, 0.8);
                        border-radius: 10px;
                        text-align: center;
                        padding-top: 32px;
                        padding-bottom: 32px;

                        h2 {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: rgba(48, 133, 221, 1);
                        }

                        h1 {
                            font-size: 25px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: rgba(233, 86, 37, 1);
                            margin-top: 20px;
                            margin-bottom: 23px;
                        }

                        p {
                            color: rgba(85, 85, 85, 1);
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .bottom {
            margin-top: 30px;

            .t {
                text-align: right;
                margin-bottom: 10px;

                ::v-deep .el-date-editor .el-range-separator {
                    width: 6%;
                }

                span {
                    line-height: 28px;
                    border: 1px solid rgba(155, 155, 155, 1);
                    border-radius: 4px;
                    color: #555;
                    display: inline-block;
                    padding: 1px 10px;
                    font-size: 14px;
                    cursor: pointer;
                    margin-right: 15px;
                }

                span.active {
                    background: rgba(95, 164, 235, 1);
                    border: 1px solid rgba(95, 164, 235, 1);
                    color: #fff;
                }
            }

            .left {
                .k {
                    padding: 20px 30px;
                    border: 1px solid rgba(181, 181, 181, 1);
                    border-radius: 4px;

                    .kt {
                        display: flex;
                        justify-content: space-between;

                        .l {
                            span {
                                margin-right: 20px;
                                font-size: 18px;
                                color: #555;
                                font-weight: bold;
                            }

                            ::v-deep .el-input__inner {
                                border: 0;
                                width: 70px;
                                padding: 0;
                            }
                        }

                        .r {
                            ::v-deep .el-input__inner {
                                width: 120px;
                            }
                        }
                    }

                    .tubiao {
                        margin-top: 20px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;

                        .kk {
                            flex: 0 0 50%;
                            padding: 15px 20px;
                        }

                        .kuang {
                            border: 1px solid rgba(181, 181, 181, 1);
                            border-radius: 4px;
                        }
                    }
                }
            }

            .right {
                .k {
                    padding: 20px 30px;
                    border: 1px solid rgba(181, 181, 181, 1);
                    border-radius: 4px;

                    .kt {
                        height: 40px;

                        span {
                            margin-right: 20px;
                            font-size: 18px;
                            color: #555;
                            font-weight: bold;
                        }

                        ::v-deep .el-input__inner {
                            border: 0;
                            width: 70px;
                            padding: 0;
                        }
                    }

                    .tubiao {
                        padding: 15px 20px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
</style>
