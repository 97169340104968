function time(e, text) {
    const start = new Date(new Date(new Date().toLocaleDateString()));
    const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
    let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
    let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
    let yue = start.getTime() - 3600 * 1000 * 24 * 30;
    if (text[e] == '最近一周') {
        return [zhou, end];
    } else if (text[e] == '最近一月') {
        return [yue, end];
    } else if (text[e] == '今日') {
        return [start.getTime(), end];
    } else if (text[e] == '全部') {
        return [];
    } else if (text[e] == '昨日') {
        return [zuo, start.getTime() - 1];
        // return [zuo, end];
    }
}
export { time };
